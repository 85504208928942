var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "q-px-md justify-center" }, [
    _c("br"),
    _c("div", { staticClass: "text-h5", attrs: { align: "center" } }, [
      _vm._v("Ricerca Clienti che hanno lo stesso numero di cellulare"),
    ]),
    _c("br"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sta_caricando,
            expression: "sta_caricando",
          },
        ],
        staticClass: "text-center q-pa-md q-gutter-sm absolute-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3" }),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 q-mr-sm" },
        [
          _c("q-input", {
            staticClass: "q-mb-sm",
            attrs: { outlined: "", label: "Numero di cellulare" },
            model: {
              value: _vm.Cellulare,
              callback: function ($$v) {
                _vm.Cellulare = $$v
              },
              expression: "Cellulare",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-md-3" }),
      _c(
        "div",
        { staticClass: "col-md-12", attrs: { align: "center" } },
        [
          _c("br"),
          _c("QQButton", {
            attrs: {
              label: "Cerca Clienti",
              color: "blue-grey",
              icon: "mdi-note-search-outline",
              size: "sm",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onCercaClienti()
              },
            },
          }),
          _c("hr"),
          _c("q-table", {
            staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
            attrs: {
              title: "",
              data: _vm.righe,
              columns: _vm.colonne,
              filter: _vm.filter,
              dense: "",
              "row-key": "name",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (props) {
                  return [
                    _c(
                      "q-tr",
                      { attrs: { props: props } },
                      _vm._l(props.cols, function (col) {
                        return _c(
                          "q-th",
                          {
                            key: col.name,
                            staticStyle: {
                              "font-size": "14px",
                              "border-bottom": "1px SOLID #404040",
                              "background-color": "#ffcc99",
                              color: "#000",
                            },
                            attrs: { props: props },
                          },
                          [_c("strong", [_vm._v(_vm._s(col.label))])]
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }